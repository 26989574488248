import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import Auth from '@aws-amplify/auth';
import Alert from '@mui/material/Alert';
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

const { REACT_APP_BACKEND_URL } = process.env;

export default function RappiInvoices({ path }) {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [label, setLabel] = useState();

  const formik = useFormik({
    initialValues: {
      rappiId: '',
    },
    onSubmit: async ({ rappiId }) => {
      setError(false);
      setLoading(true);
      setLabel();

      try {
        const currentSession = await Auth.currentSession();
        const accessToken = currentSession.getIdToken().getJwtToken();

        const cleanRappiId = rappiId.trim();
        const response = await fetch(
          `${REACT_APP_BACKEND_URL}/${path}?rappiOrder=${cleanRappiId}`,
          {
            headers: {
              authorization: accessToken,
            },
          },
        );
        const { invoicePDF } = await response.json();
        setLabel(invoicePDF);
        setLoading(false);
      } catch {
        setLoading(false);
        setError(true);
      }
    },
  });

  return (
    <>
      <Stack spacing={2}>
        <div>
          <Typography variant="h5" gutterBottom>
            Generador de boletas de Rappi
          </Typography>
          <Typography variant="body1" gutterBottom>
            Debes ingresar el número de orden de Rappi para poder emitir la
            boleta
          </Typography>
        </div>
        <Alert severity="warning">
          Ten en cuenta que esto es para emitir una boleta y debe hacerse una
          vez. En caso de querer realizar consultas, debes ingresar directamente
          a OpenFactura.
        </Alert>

        <Alert severity="warning">
          En caso de haber una diferencia de la boleta emitida con lo indicado
          por Rappi, anota el numero de boleta generado y emite una nueva
          manualmente. Informa sobre esta situación a Tecnología junto con el
          número de boleta.
        </Alert>

        {error && (
          <Alert severity="error">
            Ha ocurrido un error! Revisa los datos e intenta de nuevo.
          </Alert>
        )}
        <form onSubmit={formik.handleSubmit}>
          <Stack spacing={2}>
            <TextField
              required
              id="rappiId"
              name="rappiId"
              label="Orden Rappi"
              value={formik.values.rappiId}
              onChange={formik.handleChange}
              error={formik.touched.rappiId && Boolean(formik.errors.rappiId)}
              helperText={formik.touched.rappiId && formik.errors.rappiId}
            />

            <LoadingButton
              loading={loading}
              color="primary"
              variant="contained"
              type="submit"
            >
              Generar
            </LoadingButton>
          </Stack>
        </form>
        {label && (
          <>
            <object
              data={`data:application/pdf;base64,${label}`}
              height="350px"
            >
              test
            </object>
          </>
        )}
      </Stack>
    </>
  );
}

RappiInvoices.propTypes = {
  path: PropTypes.string,
};

RappiInvoices.defaultProps = {
  path: 'rappi-invoices',
};
