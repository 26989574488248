import React, { useState } from 'react';
import { useFormik } from 'formik';
import Auth from '@aws-amplify/auth';
import Alert from '@mui/material/Alert';
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

const { REACT_APP_BACKEND_URL } = process.env;

export default function FranuiInvoices() {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [label, setLabel] = useState();

  const formik = useFormik({
    initialValues: {
      orderId: '',
      store: 'KN',
    },
    onSubmit: async ({ orderId, store }) => {
      setError(false);
      setLoading(true);
      setLabel();

      try {
        const currentSession = await Auth.currentSession();
        const accessToken = currentSession.getIdToken().getJwtToken();

        const cleanOrderId = orderId.trim();
        const response = await fetch(
          `${REACT_APP_BACKEND_URL}/franui-invoices?orderId=${cleanOrderId}&store=${store}`,
          {
            headers: {
              authorization: accessToken,
            },
          },
        );
        const { invoicePDF } = await response.json();
        setLabel(invoicePDF);
        setLoading(false);
      } catch {
        setLoading(false);
        setError(true);
      }
    },
  });

  return (
    <>
      <Stack spacing={2}>
        <div>
          <Typography variant="h5" gutterBottom>
            Generador de boletas de Rappi
          </Typography>
          <Typography variant="body1" gutterBottom>
            Debes ingresar el número de orden de Rappi para poder emitir la
            boleta
          </Typography>
        </div>
        <Alert severity="warning">
          Ten en cuenta que esto es para emitir una boleta y debe hacerse una
          vez. En caso de querer realizar consultas, debes ingresar directamente
          a OpenFactura.
        </Alert>

        <Alert severity="warning">
          En caso de haber una diferencia de la boleta emitida con lo indicado
          por Rappi, anota el numero de boleta generado y emite una nueva
          manualmente. Informa sobre esta situación a Tecnología junto con el
          número de boleta.
        </Alert>

        {error && (
          <Alert severity="error">
            Ha ocurrido un error! Revisa los datos e intenta de nuevo.
          </Alert>
        )}
        <form onSubmit={formik.handleSubmit}>
          <Stack spacing={2}>
            <FormControl fullWidth>
              <InputLabel id="store-select">Tienda</InputLabel>
              <Select
                id="store-select"
                name="store"
                value={formik.values.store}
                label="Tienda"
                onChange={formik.handleChange}
              >
                <MenuItem value="KN">Kennedy</MenuItem>
                <MenuItem value="LL">Los Leones</MenuItem>
                <MenuItem value="PN">Puente Nuevo</MenuItem>
                <MenuItem value="LP">Las Parcelas</MenuItem>
                <MenuItem value="VINA">Viña del Mar</MenuItem>
              </Select>
            </FormControl>

            <TextField
              required
              id="orderId"
              name="orderId"
              label="Orden Rappi"
              value={formik.values.orderId}
              onChange={formik.handleChange}
              error={formik.touched.orderId && Boolean(formik.errors.orderId)}
              helperText={formik.touched.orderId && formik.errors.orderId}
            />

            <LoadingButton
              loading={loading}
              color="primary"
              variant="contained"
              type="submit"
            >
              Generar
            </LoadingButton>
          </Stack>
        </form>
        {label && (
          <>
            <object
              data={`data:application/pdf;base64,${label}`}
              height="350px"
            >
              test
            </object>
          </>
        )}
      </Stack>
    </>
  );
}
