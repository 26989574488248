import keymirror from 'keymirror';

export const STATUSES = keymirror({
  created: null,
  pendingApproval: null,
  pending: null,
  processing: null,
  readyAtStore: null,
  invoiced: null,
  outForDelivery: null,
  delivered: null,
  canceled: null,
  error: null,
  backorder: null,
});

export const STATUSES_TRANSLATIONS = {
  [STATUSES.created]: 'Creada',
  [STATUSES.pendingApproval]: 'Por aprobar',
  [STATUSES.pending]: 'Pendiente',
  [STATUSES.processing]: 'Procesando',
  [STATUSES.readyAtStore]: 'Listo en tienda',
  [STATUSES.invoiced]: 'Facturada',
  [STATUSES.outForDelivery]: 'En reparto',
  [STATUSES.delivered]: 'Entregada',
  [STATUSES.canceled]: 'Cancelada',
  [STATUSES.error]: 'Error',
  [STATUSES.backorder]: 'Sin inventario',
};
