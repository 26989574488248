import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { STATUSES_TRANSLATIONS } from '../../lib/andromeda/statuses';

export default function OrderSummary({ order }) {
  const { addressLine1, addressLine2, city, state, country, postalCode } =
    order.shipping.address;
  const manualAddress = [
    addressLine1,
    addressLine2,
    city,
    state,
    country,
    postalCode,
  ]
    .filter((element) => !!element)
    .join(', ');
  return (
    <>
      <Stack spacing={4}>
        <div>
          <Typography variant="h5">General</Typography>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Cliente</TableCell>
                  <TableCell>Plataforma</TableCell>
                  <TableCell>Numero Orden</TableCell>
                  <TableCell>Estado</TableCell>
                  <TableCell>Tienda</TableCell>
                  <TableCell>Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow
                  key="andromeda-order-addresses"
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {order.seller.name}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {order.sourceOrder.systemName}
                  </TableCell>
                  <TableCell>
                    {order.sourceOrder.orderNumber || order.sourceOrder.orderId}
                  </TableCell>
                  <TableCell>{STATUSES_TRANSLATIONS[order.status]}</TableCell>
                  <TableCell>{order.warehouse.name}</TableCell>
                  <TableCell>$ {Math.round(order.totalValue)}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div>
          <Typography variant="h5">Boleta</Typography>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Estado</TableCell>
                  <TableCell>Boleta</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow
                  key="andromeda-order-addresses"
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  {order.invoiceData && order.invoiceData.invoiceURL ? (
                    <>
                      <TableCell component="th" scope="row">
                        Emitida
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Button
                          href={order.invoiceData.invoiceURL}
                          target="_blank"
                        >
                          Ver Boleta
                        </Button>
                      </TableCell>
                    </>
                  ) : (
                    <>
                      <TableCell component="th" scope="row">
                        No Emitida
                      </TableCell>
                      <TableCell component="th" scope="row">
                        No Disponible
                      </TableCell>
                    </>
                  )}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div>
          <Typography variant="h5">Cliente</Typography>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Nombre</TableCell>
                  <TableCell>Telefono</TableCell>
                  <TableCell>Email</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow
                  key="andromeda-order-addresses"
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {order.customer.firstName} {order.customer.lastName || ''}
                  </TableCell>
                  <TableCell>{order.customer.phone}</TableCell>
                  <TableCell>{order.customer.email}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div>
          <Typography variant="h5">Direcciones</Typography>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Ingresada por Usuario</TableCell>
                  <TableCell>Calculada</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow
                  key="andromeda-order-addresses"
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {manualAddress}
                  </TableCell>
                  <TableCell align="right">
                    {order.shipping.computedAddress.label} (
                    {order.shipping.computedAddress.complement || ''})
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div>
          <Typography variant="h5">Items</Typography>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Descripción</TableCell>
                  <TableCell align="right">SKU</TableCell>
                  <TableCell align="right">Cantidad</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {order.items.map((item) => (
                  <TableRow
                    key={item.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {item.description}
                    </TableCell>
                    <TableCell align="right">{item.sku}</TableCell>
                    <TableCell align="right">{item.quantity}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Stack>
    </>
  );
}

OrderSummary.propTypes = {
  order: PropTypes.shape({
    status: PropTypes.string,
    totalValue: PropTypes.number,
    seller: PropTypes.shape({
      name: PropTypes.string,
    }),
    warehouse: PropTypes.shape({
      name: PropTypes.string,
    }),
    sourceOrder: PropTypes.shape({
      systemName: PropTypes.string,
      orderId: PropTypes.string,
      orderNumber: PropTypes.string,
    }),
    invoiceData: PropTypes.shape({
      invoiceURL: PropTypes.string,
    }),
    items: PropTypes.arrayOf(
      PropTypes.shape({
        description: PropTypes.string,
        sku: PropTypes.string,
        quantity: PropTypes.number,
      }),
    ),
    shipping: {
      address: {
        addressLine1: PropTypes.string,
        addressLine2: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        country: PropTypes.string,
        postalCode: PropTypes.string,
      },
      computedAddress: {
        label: PropTypes.string,
        complement: PropTypes.string,
      },
    },
    customer: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      phone: PropTypes.string,
      email: PropTypes.string,
    }),
  }).isRequired,
};
