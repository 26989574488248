import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import Auth from '@aws-amplify/auth';
import Alert from '@mui/material/Alert';
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

const { REACT_APP_BACKEND_URL } = process.env;

export default function OrderSearch({ title, description, children }) {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentOrder, setCurrentOrder] = useState();

  const formik = useFormik({
    initialValues: {
      shipedgeOrderId: '',
    },
    onSubmit: async ({ shipedgeOrderId }) => {
      setError(false);
      setLoading(true);
      setCurrentOrder();

      try {
        const currentSession = await Auth.currentSession();
        const accessToken = currentSession.getIdToken().getJwtToken();

        const cleanShipedgeOrderId = shipedgeOrderId.trim();
        const response = await fetch(
          `${REACT_APP_BACKEND_URL}/andromeda/orders/shipedge/${cleanShipedgeOrderId}`,
          {
            headers: {
              authorization: accessToken,
            },
          },
        );
        const order = await response.json();
        setCurrentOrder(order);
        setLoading(false);
      } catch {
        setLoading(false);
        setError(true);
      }
    },
  });

  return (
    <>
      <Stack spacing={2}>
        <Alert severity="info">En pruebas</Alert>
        <div>
          <Typography variant="h4" gutterBottom>
            {title}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {description}
          </Typography>
        </div>

        {error && (
          <Alert severity="error">
            Ha ocurrido un error! Revisa los datos e intenta de nuevo.
          </Alert>
        )}
        <form onSubmit={formik.handleSubmit}>
          <Stack spacing={2}>
            <TextField
              required
              id="shipedgeOrderId"
              name="shipedgeOrderId"
              label="Numero Orden Shipedge"
              value={formik.values.shipedgeOrderId}
              onChange={formik.handleChange}
              error={
                formik.touched.shipedgeOrderId &&
                Boolean(formik.errors.shipedgeOrderId)
              }
              helperText={
                formik.touched.shipedgeOrderId && formik.errors.shipedgeOrderId
              }
            />

            <LoadingButton
              loading={loading}
              color="primary"
              variant="contained"
              type="submit"
            >
              Consultar
            </LoadingButton>
          </Stack>
        </form>
        {currentOrder && React.cloneElement(children, { order: currentOrder })}
      </Stack>
    </>
  );
}

OrderSearch.defaultProps = {
  title: 'Órdenes Andromeda',
  description:
    'Este formulario te permitirá consultar el estado de una orden en Andrómeda y así poder obtener, por ejemplo, las boletas generadas automáticamente.',
};

OrderSearch.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.node.isRequired,
};
