import React from 'react';
import OrderSearch from './OrderSearch';
import UpdateDeliveryStatus from './forms/UpdateDeliveryStatus';

export default function DeliveryUpdate() {
  return (
    <OrderSearch
      title="Actualizar manualmente estado de envío"
      description="Este formulario te permite actualizar el estado del envío de una orden de Andromeda y así notificar a los usuarios"
    >
      <UpdateDeliveryStatus />
    </OrderSearch>
  );
}
