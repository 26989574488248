import React from 'react';
import OrderSearch from './OrderSearch';
import OrderSummary from './OrderSummary';

export default function Summary() {
  return (
    <OrderSearch>
      <OrderSummary />
    </OrderSearch>
  );
}
