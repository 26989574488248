import React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

export default function Home() {
  return (
    <>
      <Stack spacing={2}>
        <Typography variant="h4">Herramientas internas</Typography>
        <Typography>
          En este sitio podrás encontrar herramientas internas para automatizar
          ciertos procesos de StoreCentral
        </Typography>
        <Typography>
          En caso de tener algún problema puedes reportarlo a{' '}
          <strong>tecnologia@grupocentral.io</strong>
        </Typography>
      </Stack>
    </>
  );
}
