import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import Auth from '@aws-amplify/auth';
import Alert from '@mui/material/Alert';
import LoadingButton from '@mui/lab/LoadingButton';
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const { REACT_APP_BACKEND_URL } = process.env;

export default function UpdateDeliveryAddress({ order }) {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      street: order.shipping.computedAddress.street,
      streetNumber: order.shipping.computedAddress.addressNumber,
      complement: order.shipping.computedAddress.complement,
      municipality: order.shipping.computedAddress.municipality,
      city: order.shipping.computedAddress.subRegion,
      region: order.shipping.computedAddress.region,
      postalCode: order.shipping.computedAddress.postalCode,
      country: 'CHL',
    },
    onSubmit: async ({
      street,
      streetNumber,
      complement,
      municipality,
      city,
      region,
      postalCode,
      country,
    }) => {
      setError(false);
      setSuccess(false);
      setLoading(true);

      try {
        const currentSession = await Auth.currentSession();
        const accessToken = currentSession.getIdToken().getJwtToken();

        const fetchResult = await fetch(
          `${REACT_APP_BACKEND_URL}/andromeda/orders/${order.id}/update-address`,
          {
            method: 'PATCH',
            headers: {
              authorization: accessToken,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              street,
              streetNumber,
              complement,
              municipality,
              city,
              region,
              postalCode,
              country,
            }),
          },
        );

        if (!fetchResult.ok) {
          setLoading(false);
          setError(true);
          return;
        }

        setLoading(false);
        setSuccess(true);
      } catch {
        setLoading(false);
        setError(true);
      }
    },
  });

  const { addressLine1, addressLine2, city, state, country, postalCode } =
    order.shipping.address;
  const manualAddress = [
    addressLine1,
    addressLine2,
    city,
    state,
    country,
    postalCode,
  ]
    .filter((element) => !!element)
    .join(', ');

  return (
    <>
      <Stack spacing={2}>
        <Alert severity="warning">
          Recuerda que este cambio modificará la dirección de la orden y será
          utilizada para el envío automático
        </Alert>

        {error && (
          <Alert severity="error">
            Ha ocurrido un error! Revisa los datos e intenta de nuevo.
          </Alert>
        )}

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Ingresada por Usuario</TableCell>
                <TableCell>Calculada</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                key="andromeda-order-addresses"
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {manualAddress}
                </TableCell>
                <TableCell align="right">
                  {order.shipping.computedAddress.label} (
                  {order.shipping.computedAddress.complement || ''})
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <form onSubmit={formik.handleSubmit}>
          <Stack spacing={2}>
            <TextField
              required
              id="street"
              name="street"
              label="Calle"
              value={formik.values.street}
              onChange={formik.handleChange}
              error={formik.touched.street && Boolean(formik.errors.street)}
              helperText={formik.touched.street && formik.errors.street}
            />
            <TextField
              required
              id="streetNumber"
              name="streetNumber"
              label="Numero"
              value={formik.values.streetNumber}
              onChange={formik.handleChange}
              error={
                formik.touched.streetNumber &&
                Boolean(formik.errors.streetNumber)
              }
              helperText={
                formik.touched.streetNumber && formik.errors.streetNumber
              }
            />
            <TextField
              id="complement"
              name="complement"
              label="Complemento (Ej: casa 2)"
              value={formik.values.complement}
              onChange={formik.handleChange}
              error={
                formik.touched.complement && Boolean(formik.errors.complement)
              }
              helperText={formik.touched.complement && formik.errors.complement}
            />
            <TextField
              required
              id="municipality"
              name="municipality"
              label="Comuna"
              value={formik.values.municipality}
              onChange={formik.handleChange}
              error={
                formik.touched.municipality &&
                Boolean(formik.errors.municipality)
              }
              helperText={
                formik.touched.municipality && formik.errors.municipality
              }
            />
            <TextField
              required
              id="city"
              name="city"
              label="Ciudad"
              value={formik.values.city}
              onChange={formik.handleChange}
              error={formik.touched.city && Boolean(formik.errors.city)}
              helperText={formik.touched.city && formik.errors.city}
            />
            <TextField
              required
              id="region"
              name="region"
              label="Region"
              value={formik.values.region}
              onChange={formik.handleChange}
              error={formik.touched.region && Boolean(formik.errors.region)}
              helperText={formik.touched.region && formik.errors.region}
            />
            <TextField
              required
              id="postalCode"
              name="postalCode"
              label="Código Postal"
              value={formik.values.postalCode}
              onChange={formik.handleChange}
              error={
                formik.touched.postalCode && Boolean(formik.errors.postalCode)
              }
              helperText={formik.touched.postalCode && formik.errors.postalCode}
            />
            <Select
              id="country"
              name="country"
              value={formik.values.country}
              onChange={formik.handleChange}
              label="Pais"
              error={formik.touched.country && Boolean(formik.errors.country)}
            >
              <MenuItem value="CHL">Chile</MenuItem>
            </Select>

            <LoadingButton
              loading={loading}
              color="primary"
              variant="contained"
              type="submit"
            >
              Actualizar
            </LoadingButton>
          </Stack>
        </form>
        {success && (
          <Alert severity="success">Estado actualizado con éxito</Alert>
        )}
      </Stack>
    </>
  );
}

UpdateDeliveryAddress.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.string,
    status: PropTypes.string,
    shipping: PropTypes.shape({
      address: PropTypes.shape({
        addressLine1: PropTypes.string,
        addressLine2: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        country: PropTypes.string,
        postalCode: PropTypes.string,
      }),
      computedAddress: PropTypes.shape({
        street: PropTypes.string,
        addressNumber: PropTypes.string,
        complement: PropTypes.string,
        municipality: PropTypes.string,
        subRegion: PropTypes.string,
        region: PropTypes.string,
        postalCode: PropTypes.string,
        label: PropTypes.string,
      }),
    }),
  }).isRequired,
};
