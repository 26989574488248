import React from 'react';
import OrderSearch from './OrderSearch';
import UpdateDeliveryAddress from './forms/UpdateDeliveryAddress';

export default function DeliveryUpdate() {
  return (
    <OrderSearch
      title="Actualizar manualmente dirección de envío"
      description="Este formulario te permite actualizar la dirección de envío de una orden de Andromeda"
    >
      <UpdateDeliveryAddress />
    </OrderSearch>
  );
}
