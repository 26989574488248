import React, { useCallback } from 'react';
import { Link as RouterLink, Switch, Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Auth from '@aws-amplify/auth';
import orange from '@mui/material/colors/orange';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import LogoutIcon from '@mui/icons-material/Logout';
import StoreCentralLogo from '../../images/store-central.png';
import routes from '../../lib/routes';
import {
  logout,
  isLoggedInSelector,
  usernameSelector,
} from '../../lib/store/slices/auth';
import { mainListItems } from './list-items';
import RappiInvoices from '../RappiInvoices';
import ShopifyInvoices from '../ShopifyInvoices';
import Home from '../Home';
import Authentication from '../Authentication';
import FranuiInvoices from '../FranuiInvoices';
import JustoInvoices from '../JustoInvoices';
import AndromedaOrderSummary from '../Andromeda/Summary';
import AndromedaOrderLabel from '../Andromeda/OrderLabel';
import AndromedaDeliveryUpdate from '../Andromeda/DeliveryUpdate';
import AndromedaAddressUpdate from '../Andromeda/AddressUpdate';

const STORECENTRAL_THEME = createTheme({
  palette: {
    secondary: orange,
  },
});

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      align="center"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {'Copyright © '}
      <Link color="inherit" href="https://storecentral.cl/" target="_blank">
        StoreCentral
      </Link>{' '}
      {new Date().getFullYear()}.
    </Typography>
  );
}

const drawerWidth = 240;

const AppBar = styled(MuiAppBar)(({ theme }) => ({
  backgroundColor: 'black',
  zIndex: theme.zIndex.drawer + 1,
  marginLeft: drawerWidth,
  width: `calc(100% - ${drawerWidth}px)`,
}));

const Drawer = styled(MuiDrawer)(() => ({
  '& .MuiDrawer-paper': {
    backgroundColor: 'black',
    color: 'white',
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    boxSizing: 'border-box',
  },
}));

export default function Dashboard() {
  const dispatch = useDispatch();
  const currentUsername = useSelector(usernameSelector);
  const isLoggedIn = useSelector(isLoggedInSelector);

  const logoutCallback = useCallback(() => {
    Auth.signOut().then(() => dispatch(logout()));
  });

  if (!isLoggedIn) return <Authentication />;

  return (
    <ThemeProvider theme={STORECENTRAL_THEME}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute">
          <Toolbar sx={{ justifyContent: 'flex-end' }}>
            <Typography>Hola {currentUsername}!</Typography>
            <IconButton color="inherit" onClick={logoutCallback}>
              <LogoutIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              px: [1],
            }}
          >
            <RouterLink to={routes.home}>
              <img height="40px" src={StoreCentralLogo} alt="logo" />
            </RouterLink>
          </Toolbar>
          <Divider />
          <List>{mainListItems}</List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Switch>
                    <Route exact path={routes.home}>
                      <Home />
                    </Route>
                    <Route path={routes.rappiInvoices}>
                      <RappiInvoices />
                    </Route>
                    <Route path={routes.rappiInvoicesPuenteNuevo}>
                      <RappiInvoices path="rappi-invoices-puente-nuevo" />
                    </Route>
                    <Route path={routes.franuiInvoices}>
                      <FranuiInvoices />
                    </Route>
                    <Route path={routes.franuiJustoInvoices}>
                      <JustoInvoices
                        path="franui-justo-invoices"
                        name="Franui"
                      />
                    </Route>
                    <Route path={routes.kustodiaShopifyInvoices}>
                      <ShopifyInvoices
                        path="kustodia-shopify-invoices"
                        name="Kustodia"
                      />
                    </Route>
                    <Route path={routes.bebesitJustoInvoices}>
                      <JustoInvoices path="bebesit-invoices" name="Bebesit" />
                    </Route>
                    <Route path={routes.fdidierJustoInvoices}>
                      <JustoInvoices
                        path="fdidier-justo-invoices"
                        name="Felipe Didier"
                      />
                    </Route>
                    <Route path={routes.andromedaOrders}>
                      <AndromedaOrderSummary />
                    </Route>
                    <Route path={routes.andromedaLabels}>
                      <AndromedaOrderLabel />
                    </Route>
                    <Route path={routes.andromedaDeliveryStatusUpdate}>
                      <AndromedaDeliveryUpdate />
                    </Route>
                    <Route path={routes.andromedaDeliveryAddressUpdate}>
                      <AndromedaAddressUpdate />
                    </Route>
                  </Switch>
                </Paper>
              </Grid>
            </Grid>
            <Copyright sx={{ pt: 4 }} />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
