import React from 'react';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ReceiptIcon from '@mui/icons-material/Receipt';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import { Link } from 'react-router-dom';
import routes from '../../lib/routes';

// eslint-disable-next-line import/prefer-default-export
export const mainListItems = (
  <div>
    <ListItem
      sx={{ color: 'white' }}
      color="inherit"
      component={Link}
      to={routes.andromedaOrders}
    >
      <ListItemIcon>
        <WorkspacesIcon sx={{ color: 'white' }} />
      </ListItemIcon>
      <ListItemText primary="Órdenes Andromeda" />
    </ListItem>
    <Divider />
    <ListItem
      sx={{ color: 'white' }}
      color="inherit"
      component={Link}
      to={routes.andromedaLabels}
    >
      <ListItemIcon>
        <WorkspacesIcon sx={{ color: 'white' }} />
      </ListItemIcon>
      <ListItemText primary="Etiquetas Andromeda" />
    </ListItem>
    <ListItem
      sx={{ color: 'white' }}
      color="inherit"
      component={Link}
      to={routes.andromedaDeliveryStatusUpdate}
    >
      <ListItemIcon>
        <WorkspacesIcon sx={{ color: 'white' }} />
      </ListItemIcon>
      <ListItemText primary="Act. estado envío" />
    </ListItem>

    <ListItem
      sx={{ color: 'white' }}
      color="inherit"
      component={Link}
      to={routes.andromedaDeliveryAddressUpdate}
    >
      <ListItemIcon>
        <WorkspacesIcon sx={{ color: 'white' }} />
      </ListItemIcon>
      <ListItemText primary="Act. dirección envío" />
    </ListItem>

    <Divider />

    <ListItem
      sx={{ color: 'white' }}
      color="inherit"
      component={Link}
      to={routes.rappiInvoices}
    >
      <ListItemIcon>
        <ReceiptIcon sx={{ color: 'white' }} />
      </ListItemIcon>
      <ListItemText primary="Boletas Rappi" />
    </ListItem>
    <ListItem
      sx={{ color: 'white' }}
      color="inherit"
      component={Link}
      to={routes.rappiInvoicesPuenteNuevo}
    >
      <ListItemIcon>
        <ReceiptIcon sx={{ color: 'white' }} />
      </ListItemIcon>
      <ListItemText primary="Boletas Rappi - PN" />
    </ListItem>
    <ListItem
      sx={{ color: 'white' }}
      color="inherit"
      component={Link}
      to={routes.franuiInvoices}
    >
      <ListItemIcon>
        <ReceiptIcon sx={{ color: 'white' }} />
      </ListItemIcon>
      <ListItemText primary="Boletas Franui Rappi" />
    </ListItem>
    <ListItem
      sx={{ color: 'white' }}
      color="inherit"
      component={Link}
      to={routes.franuiJustoInvoices}
    >
      <ListItemIcon>
        <ReceiptIcon sx={{ color: 'white' }} />
      </ListItemIcon>
      <ListItemText primary="Boletas Franui Justo" />
    </ListItem>
    <ListItem
      sx={{ color: 'white' }}
      color="inherit"
      component={Link}
      to={routes.bebesitJustoInvoices}
    >
      <ListItemIcon>
        <ReceiptIcon sx={{ color: 'white' }} />
      </ListItemIcon>
      <ListItemText primary="Boletas Bebesit Justo" />
    </ListItem>
    <ListItem
      sx={{ color: 'white' }}
      color="inherit"
      component={Link}
      to={routes.fdidierJustoInvoices}
    >
      <ListItemIcon>
        <ReceiptIcon sx={{ color: 'white' }} />
      </ListItemIcon>
      <ListItemText primary="Boletas FDidier Justo" />
    </ListItem>
    <ListItem
      sx={{ color: 'white' }}
      color="inherit"
      component={Link}
      to={routes.kustodiaShopifyInvoices}
    >
      <ListItemIcon>
        <ReceiptIcon sx={{ color: 'white' }} />
      </ListItemIcon>
      <ListItemText primary="Boletas Kustodia Shopify" />
    </ListItem>
  </div>
);
