export default {
  home: '/',
  rappiInvoices: '/rappi-invoices',
  rappiInvoicesPuenteNuevo: '/rappi-invoices-puente-nuevo',
  franuiInvoices: '/franui-invoices',
  franuiJustoInvoices: '/franui-justo-invoices',
  kustodiaShopifyInvoices: '/kustodia-shopify-invoices',
  bebesitJustoInvoices: '/bebesit-justo-invoices',
  fdidierJustoInvoices: '/fdidier-justo-invoices',
  andromedaOrders: '/andromeda/orders',
  andromedaLabels: '/andromeda/labels',
  andromedaDeliveryStatusUpdate: '/andromeda/delivery-status-update',
  andromedaDeliveryAddressUpdate: '/andromeda/delivery-address-update',
};
