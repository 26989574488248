import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import Auth from '@aws-amplify/auth';
import Alert from '@mui/material/Alert';
import LoadingButton from '@mui/lab/LoadingButton';
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import {
  STATUSES,
  STATUSES_TRANSLATIONS,
} from '../../../lib/andromeda/statuses';

const { REACT_APP_BACKEND_URL } = process.env;

export default function UpdateDeliveryStatus({ order }) {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      orderStatus: '',
    },
    onSubmit: async ({ orderStatus }) => {
      setError(false);
      setSuccess(false);
      setLoading(true);

      try {
        const currentSession = await Auth.currentSession();
        const accessToken = currentSession.getIdToken().getJwtToken();

        const fetchResult = await fetch(
          `${REACT_APP_BACKEND_URL}/andromeda/orders/${order.id}/update-delivery-status`,
          {
            method: 'PUT',
            headers: {
              authorization: accessToken,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              status: orderStatus,
            }),
          },
        );

        if (!fetchResult.ok) {
          setLoading(false);
          setError(true);
          return;
        }

        setLoading(false);
        setSuccess(true);
      } catch {
        setLoading(false);
        setError(true);
      }
    },
  });

  return (
    <>
      <Stack spacing={2}>
        <Alert severity="warning">
          Recuerda que este cambio modificará una orden y puede lanzar
          notificaciones a los usuarios
        </Alert>

        {error && (
          <Alert severity="error">
            Ha ocurrido un error! Revisa los datos e intenta de nuevo.
          </Alert>
        )}

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableBody>
              <TableRow
                key="status"
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <Typography sx={{ fontWeight: 'bold' }}>
                    Estado Actual
                  </Typography>
                </TableCell>
                <TableCell>{STATUSES_TRANSLATIONS[order.status]}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <form onSubmit={formik.handleSubmit}>
          <Stack spacing={2}>
            <Select
              id="orderStatus"
              name="orderStatus"
              value={formik.values.orderStatus}
              onChange={formik.handleChange}
              label="Estado"
              error={
                formik.touched.orderStatus && Boolean(formik.errors.orderStatus)
              }
            >
              <MenuItem value={STATUSES.outForDelivery}>
                {STATUSES_TRANSLATIONS[STATUSES.outForDelivery]}
              </MenuItem>
              <MenuItem value={STATUSES.delivered}>
                {STATUSES_TRANSLATIONS[STATUSES.delivered]}
              </MenuItem>
            </Select>

            <LoadingButton
              loading={loading}
              color="primary"
              variant="contained"
              type="submit"
            >
              Actualizar
            </LoadingButton>
          </Stack>
        </form>
        {success && (
          <Alert severity="success">Estado actualizado con éxito</Alert>
        )}
      </Stack>
    </>
  );
}

UpdateDeliveryStatus.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.string,
    status: PropTypes.string,
  }).isRequired,
};
