import React, { useState } from 'react';
import { useFormik } from 'formik';
import Auth from '@aws-amplify/auth';
import Alert from '@mui/material/Alert';
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

const { REACT_APP_BACKEND_URL } = process.env;

export default function OrderLabel() {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [label, setLabel] = useState();

  const formik = useFormik({
    initialValues: {
      shipedgeOrderId: '',
      packages: 1,
    },
    onSubmit: async ({ shipedgeOrderId, packages }) => {
      setError(false);
      setLoading(true);
      setLabel();

      try {
        const currentSession = await Auth.currentSession();
        const accessToken = currentSession.getIdToken().getJwtToken();

        const cleanShipedgeOrderId = shipedgeOrderId.trim().split('-')[0];
        const response = await fetch(
          `${REACT_APP_BACKEND_URL}/andromeda/orders/shipedge/${cleanShipedgeOrderId}/labels?packages=${packages}`,
          {
            headers: {
              authorization: accessToken,
            },
          },
        );
        const { label: orderLabel } = await response.json();
        setLabel(orderLabel);
        setLoading(false);
      } catch {
        setLoading(false);
        setError(true);
      }
    },
  });

  return (
    <>
      <Stack spacing={2}>
        <Alert severity="info">En pruebas</Alert>
        <div>
          <Typography variant="h4" gutterBottom>
            Generador de etiquetas de órdenes de Andromeda
          </Typography>

          <Typography variant="body1" gutterBottom>
            Este formulario permite generar etiquetas para las órdenes que han
            ingresado a Shipege y que se encuentran en Andrómeda
          </Typography>
        </div>

        {error && (
          <Alert severity="error">
            Ha ocurrido un error! Revisa los datos e intenta de nuevo.
          </Alert>
        )}

        <form onSubmit={formik.handleSubmit}>
          <Stack spacing={2}>
            <TextField
              required
              id="shipedgeOrderId"
              name="shipedgeOrderId"
              label="Orden Shipedge"
              value={formik.values.shipedgeOrderId}
              onChange={formik.handleChange}
              error={
                formik.touched.shipedgeOrderId &&
                Boolean(formik.errors.shipedgeOrderId)
              }
              helperText={
                formik.touched.shipedgeOrderId && formik.errors.shipedgeOrderId
              }
            />
            <TextField
              required
              id="packages"
              name="packages"
              label="Número de bultos"
              value={formik.values.packages}
              onChange={formik.handleChange}
              error={formik.touched.packages && Boolean(formik.errors.packages)}
              helperText={formik.touched.packages && formik.errors.packages}
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            />

            <LoadingButton
              loading={loading}
              color="primary"
              variant="contained"
              type="submit"
            >
              Generar
            </LoadingButton>
          </Stack>
        </form>
        {label && (
          <>
            <object
              data={`data:application/pdf;base64,${label}`}
              height="350px"
            >
              test
            </object>
          </>
        )}
      </Stack>
    </>
  );
}
